// Déclaration statique de tout les fichiers JSON pour que le compilateur React les mettent dans le bundle final

import LangActivityAccountEntry from './LangActivityAccountEntry.json';
import LangActivityReport from './LangActivityReport.json';
import LangCharge from './LangCharge.json';
import LangCommercialAgreement from './LangCommercialAgreement.json';
import LangContact from './LangContact.json';
import LangContractor from './LangContractor.json';
import LangCustomer from './LangCustomer.json';
import LangDashboard from './LangDashboard.json';
import LangGeneral from './LangGeneral.json';
import LangInvoiceRequest from './LangInvoiceRequest.json';
import LangLogin from './LangLogin.json';
import LangSalesInvoiced from './LangSalesInvoiced.json';
import LangSettings from './LangSettings.json';
import LangTraining from './LangTraining.json';
import LangWage from './LangWage.json';
import LangWorkContract from './LangWorkContract.json';

export default {
    langs: {
        FRA: 'fr',
        ENU: 'en'
    },
    resources: [
        LangActivityAccountEntry,
        LangActivityReport,
        LangCharge,
        LangCommercialAgreement,
        LangContact,
        LangContractor,
        LangCustomer,
        LangDashboard,
        LangGeneral,
        LangInvoiceRequest,
        LangLogin,
        LangSalesInvoiced,
        LangSettings,
        LangTraining,
        LangWage,
        LangWorkContract
    ]
};
